.App {
  text-align: center;
  background-color: #282c34;
  color: white;
}

.App-link {
  color: #61dafb;
}

.section {
  margin: 0 auto;
  min-height: 100vh;
  max-width: 800px;
  padding: 0 1rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
