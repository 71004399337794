.App-story {
  margin: 0 auto;
  min-height: 100vh;
  max-width: 800px;
  padding: 0 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.App-story p {
  margin: 1rem auto;
  font-size: 1.5rem;
}
